// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/useAccessControl.tsx"
);
import.meta.hot.lastModified = "1737459672000";
}
// REMIX HMR END

import { useCallback, useContext, useEffect, useState } from 'react';
import { GeneralContext } from '~/utils/GeneralContext';

type AccessControlType = {
  CanDoAll: boolean;
  CanRead: boolean;
  CanCreate: boolean;
  CanUpdate: boolean;
  CanDelete: boolean;
};

type Role = {
  Id: number;
  Name: string;
  Label: string;
  Level: number;
  Parent: number;
};

export const ROLE_NAME = {
  SYSTEM_ADMIN: 'system-admin',
  SYSTEM_STAFF: 'system-staff',
  CUSTODIAN: 'custodian',
  COMPANY_GROUP_USER: 'company-group-user',
  COMPANY_USER: 'company-user',
  EDUCATION_INSTITUTION_OFFICER: 'education-institution-officer',
  AGENT_OFFICER: 'agent-officer',
  AUDITOR: 'auditor',
  BENEFICIARY: 'beneficiary',
};

const useAccessControl = (resourceName?: string) => {
  const { generalContextValue } = useContext(GeneralContext);
  const { permissions, user, resources } = generalContextValue.userInfo;
  const { myCompanyInfo } = generalContextValue;
  const { active_role } = user || {};
  const { eioInfo } = generalContextValue;
  const currentRole =
    generalContextValue.userInfo?.roles?.find(
      (role: any) => role.id === active_role
    ) || {};
  const UserRole: Role = {
    Id: currentRole.id,
    Name: currentRole.name,
    Label: currentRole.label,
    Level: currentRole.level,
    Parent: currentRole.role_parent,
  };

  const isSysAdmin = UserRole?.Name === ROLE_NAME.SYSTEM_ADMIN;
  const isSysStaff = UserRole?.Name === ROLE_NAME.SYSTEM_STAFF;
  const isCompanyGroupUser = UserRole?.Name === ROLE_NAME.COMPANY_GROUP_USER;
  const isCompanyUser = UserRole?.Name === ROLE_NAME.COMPANY_USER;
  const isEio = UserRole?.Name === ROLE_NAME.EDUCATION_INSTITUTION_OFFICER;
  const isCustodian = UserRole?.Name === ROLE_NAME.CUSTODIAN;

  const [currentUser, setCurrentUser] = useState<AccessControlType>({
    CanDoAll: false,
    CanRead: false,
    CanCreate: false,
    CanUpdate: false,
    CanDelete: false,
  });

  const getCurrentPageResource = (resources: string[]) => {
    try {
      return resources.find((item: any) => item?.resource === resourceName);
    } catch (error) {
      throw new Error(error + '');
    }
  };

  const checkAuthorizationPermission = useCallback(
    (resourceCollection: any) => {
      const currentResource: any = getCurrentPageResource(resourceCollection);
      const listAccessControl: [] = currentResource?.accessControl.split(':');
      let userAcl: AccessControlType = currentUser;
      if (listAccessControl) {
        listAccessControl.forEach((element) => {
          if (element == '*') {
            userAcl = {
              CanDoAll: true,
              CanCreate: true,
              CanRead: true,
              CanUpdate: true,
              CanDelete: true,
            };
          }
          if (element == 'create') {
            userAcl = { ...userAcl, CanCreate: true };
          }
          if (element == 'read') {
            userAcl = { ...userAcl, CanRead: true };
          }
          if (element == 'update') {
            userAcl = { ...userAcl, CanUpdate: true };
          }
          if (element == 'delete') {
            userAcl = { ...userAcl, CanDelete: true };
          }
        });
      }
      setCurrentUser(userAcl);
    },
    []
  );

  useEffect(() => {
    if (permissions) {
      checkAuthorizationPermission(permissions);
    }
  }, [permissions]);

  const User = currentUser;

  return {
    user,
    User,
    UserRole,
    resources,
    myCompanyInfo,
    isSysAdmin,
    isSysStaff,
    isCompanyGroupUser,
    isCompanyUser,
    isEio,
    isCustodian,
    eioInfo,
  };
};

export default useAccessControl;
