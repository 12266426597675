// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/Validator.ts"
);
import.meta.hot.lastModified = "1734499695000";
}
// REMIX HMR END

import { z } from 'zod';
import { getOnlyNumber } from '.';
import { fileSize } from '~/constants';

export const isEmail = (val: any) => {
  const regEmail = /^[a-zA-Z0-9._:$!%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
  if (!regEmail.test(val)) {
    return false;
  }
  return true;
};

export const isValidURL = (val: string) => {
  const regex = /^https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/;

  if (regex.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const isValidTitle = (val: string) => {
  // Whitelist: letters, numbers, spaces, dash, quote, double quote, slash, dot, round brackets, hash
  const allowedChars = /^[a-zA-Z0-9\s\-'"/.()#]+$/;
  return allowedChars.test(val);
};

const stringValidator = ['', null, undefined];
export const isCurrencyValid = (value: string) => {
  if (
    value.length < 1 ||
    stringValidator.includes(value) ||
    parseInt(getOnlyNumber(value)) < 0
  ) {
    return false;
  }
  return true;
};

export const withOptionalField = <T extends z.ZodTypeAny>(schema: T) =>
  schema
    .optional()
    .or(z.literal(''))
    .or(z.literal('-'))
    .or(z.literal(null))
    .or(z.literal(undefined));

export const withRequiredString = (errorMessage: string) =>
  z
    .string()
    .min(1, errorMessage)
    .refine((value) => value !== '-', errorMessage);

export const withRequiredNumber = (errorMessage: string) =>
  z.number().min(0, errorMessage).or(withRequiredString(errorMessage));

export const withRequiredBoolean = (errorMessage: string) =>
  z
    .boolean({
      required_error: errorMessage,
      message: errorMessage,
    })
    .or(withRequiredString(errorMessage));

export const getFileExt = (file: File) => {
  return file?.name?.split('.').pop()?.toLowerCase();
};
export const isValidFileExt = (file: File) => {
  const excludedExtensions = ['exe'];

  return !excludedExtensions.includes(getFileExt(file)!);
};
export const isValidFileSize = (file: File) => {
  return file.size <= fileSize['15MB'];
};
export const checkFileError = (file: File) => {
  if (!isValidFileExt(file)) {
    return 'Format not allowed';
  }

  if (!isValidFileSize(file)) {
    return 'File exceeding 15MB limit';
  }

  return null;
};
