// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/logger-service/logger-service.ts"
);
import.meta.hot.lastModified = "1737537853000";
}
// REMIX HMR END

import axios from 'axios';
import { ILoggerRequest } from './types';

export class LoggerService {
  private _baseUrl: string;
  private _userIdentity: string;

  constructor() {
    this._baseUrl = "";
    this._userIdentity = "";
  }

  public setUserIdentity(email: string) {
    this._userIdentity = email;
  }

  public setBaseUrl(url: string) {
    this._baseUrl = url;
  }

  public async error(payload: ILoggerRequest) {
    try {
      let { userId, message, details } = payload;
      const user_id = !!userId ? userId : !!this._userIdentity ? this._userIdentity : "-";
      const url = `${this._baseUrl}/logs/exception`;
      const detailInfo = {
        code: details?.code,
        status: details?.response?.status,
        statusText: details?.response?.statusText,
        headers: details?.config?.headers,
        method: details?.config?.method,
        url: details?.config?.url,
        serverInfo: details?.response?.data
      }
      const data = {
        user_id,
        error_message: {
          "all-info": {
              message,
              timestamp: new Date(),
              details: detailInfo
          }
        }
      };
      
      await axios.post(url, data);
    } catch (error) {
      console.log(error)
    }
  }
}